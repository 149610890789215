  // .mentions--singleLine .mentions__control {
  //   display: inline-block;
  //   width: 130px;
  // }

  // .mentions--singleLine .mentions__highlighter {
  //   padding: 1px;
  //   border: 2px inset transparent;
  // }

  // .mentions--singleLine .mentions__input {
  //   padding: 1px;
  //   border: 2px inset;
  // }
  
  .mentions--multiLine .mentions__control {
    font-family: var(--main-font);
    font-size: 14px;
  }

  // .mentions--multiLine .mentions__highlighter {
  //   border: 0px solid transparent;
  // }

  .mentions__input {
    color: var(--main-text);
  }
  
  .mentions--multiLine .mentions__input {
    border: none;
    outline: 0;
  }

  // .mentions__suggestions {
  //   top: -10px !important;
  //   left: 10px !important;
  // }
  
  .mentions__suggestions__list {
    background-color: white;
    font-size: 14px;
    width: auto;
    box-shadow: -2px -2px 10px 0px var(--shadow);
  }
  
  .mentions__suggestions__item {
    padding: 5px 10px;
    text-align: left;
  }
  
  .mentions__suggestions__item--focused {
    background-color: var(--main-bg);
  }

  .mentions__suggestions__item--focused span {
    color: var(--link);
  }