.wcc-dashboard {
  .wcc-card {
    width: 100%;
    max-width: 85%;
    background-color: var(--card-bg);
    margin: 30px auto;
  }

  .wcc-header {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #ebebeb;
    margin-bottom: 30px;
    padding: 0 30px;

    .wcc-menu {
      display: flex;
      flex-direction: row;

      .menu-tab {
        margin: 0 0 0 30px;
        padding: 0;
      }

      .menu-tab:first-child {
        margin-left: 0;
      }
    }

    .wcc-icon {
      background-color: var(--main-bg) !important;
    }
  }

  .wcc-icons {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;

    .wcc-iconwrap{
      flex: 0 0 30px;
      height: 30px;
      position: relative;
    }
    
    .wcc-icon {
      flex: 0 0 30px;
      height: 30px;
      cursor: pointer;
      border-radius: 5px;
      padding: 2px 10px;
      position: relative;
      margin-right: 10px;

      &.wcc-active,
      &.wcc-active > i,
      &:hover > i {
        color: var(--naranja-btn) !important;
        background-color: var(--main-bg) !important;
      }
    }

    .wcc-icon:last-child {
      margin-right: 0;
    }
  }

  .wcc-content {
    padding: 0 30px 20px;

    .wcc-title h2 {
      margin-top: 0;
    }
    .wcc-subtitle {
      margin-top: 10px;
    }
  }
}

/* Display projects in table view
*****************************************/
.wcc-projects {
  margin-top: 50px;

  table th {
    font-weight: bold;
  }
}

/* Convert table view to card view
*****************************************/
.wcc-cards {
  thead {
    display: none !important;
  }

  table tbody {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;

    tr {
      flex: 0 3 32.4%;
      height: 194px;
      border: 1px solid var(--border);
      display: block;
      margin-bottom: 1.5rem;
      padding-top: 16px;
      background-color: var(--card-bg);
      position: relative;
      display: flex;
      flex-flow: row wrap;

      td {
        flex: 0 0 100%;
        border: none;
        display: block;
        padding: 0 20px;
      }

      td.wcc-pid {
        order: 1;
        flex: 0 0 50%;
        font-weight: bold;
      }

      td.wcc-pdate {
        order: 2;
        flex: 0 0 50%;
        text-align: right !important;
      }
  
      td.wcc-panalyst {
        order: 4;
        flex: 0 0 50%;

        &:before{
          content: "Analyst ";
          font-weight: bold;
          margin-right: 30px;
        }
      }

      td.wcc-pname {
        order: 3;
        font-weight: bold;

        img{
          width: 24px;
          height: 24px;
          border-radius: 24px;
          margin-right: 5px;
          vertical-align: middle;
        }
      }

      td.wcc-pfooter {
        border-top: 1px solid var(--border);;
        order: 5;
        height: 46px;
        align-self: flex-end;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .wcc-priority{
          text-transform: capitalize;
          color: var(--naranja-btn);
        }
        .wcc-picons{
          text-align: right;
        }
      }
    }
  }
}
