// Vars
$trasition: ease all 0.4s;

// Main
body {
    background: var(--main-bg);
}

.modal-open {
    overflow: hidden;
}
* {
    box-sizing: border-box !important;
}
a {
    text-decoration: none;
}

select,
input {
    background-color: var(--card-bg);
}

select option {
    background-color: var(--card-bg);
}

.header {
    background-color: var(--card-bg);
    width: 100%;
}

.footer {
    background-color: var(--card-bg);
    width: 100%;
}

.logo {
    width: 192px;
}

.row [class^="col"] {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

input::placeholder,
textarea::placeholder {
    font-size: 14px;
    color: var(--secondary-text);
}

input:focus,
textarea:focus,
select:focus {
    outline: none !important;
}

// Text
.title {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    // color: var(--main-text);
}

.title-2 {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: lighter;
    font-size: 24px;
    line-height: 29px;
    // color: var(--main-text);
}

.title-3 {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: lighter;
    font-size: 20px;
    line-height: 24px;
    // color: var(--main-text);
}

.subtitle {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    // color: var(--main-text);
}

.title-4 {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.15px;
    // color: var(--main-text);
}

.heading {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    // color: var(--main-text);
}

.subheading {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: lighter;
    font-size: 15px;
    line-height: 18px;
    // color: var(--main-text);
}

.text {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: lighter;
    font-size: 14px;
    line-height: 17px;
    // color: var(--main-text)
}

.special-text {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.1em;
    // color: var(--secondary-text);
    text-transform: uppercase;
}

.rating-text {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    color: #44c568;
}

.error-message {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: var(--error);
    margin: 5px;
}

.text-white {
    color: white;
}

// Modifiers
.unpadify-x {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-60 {
    margin-top: 60px;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-20 {
    margin-right: 20px !important;
}
.mr-30 {
    margin-right: 30px !important;
}

.ml-5 {
    margin-left: 5px !important;
}
.ml-10 {
    margin-left: 10px !important;
}

.ml-20 {
    margin-left: 20px !important;
}
.ml-50 {
    margin-left: 50px !important;
}

.my-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.my-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.my-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.my-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.mx-5 {
    margin-right: 5px;
    margin-left: 5px;
}

.mx-10 {
    margin-right: 10px;
    margin-left: 10px;
}

.mx-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
}

.mx-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
}

.y-scroll {
    overflow-y: scroll !important;
}

.x-scroll {
    overflow-x: scroll !important;
}

.scroll {
    overflow: scroll !important;
}

.center {
    margin: 0px auto;
    text-align: center !important;
    justify-content: center;
}

.right {
    margin-right: 0px !important;
    text-align: right !important;
    justify-content: flex-end !important;
}

.left {
    margin-left: 0px !important;
    text-align: left !important;
    justify-content: flex-start !important;
}

.is-dark {
    color: var(--main-text);
}

.is-red {
    color: red;
}

.is-light {
    color: var(--secondary-text);
}

.is-white {
    color: var(--card-bg);
}

.is-orange {
    color: var(--link);
}

.is-green {
    color: #76caa0;
}

.is-bold {
    font-weight: bold;
}

.orange-btn {
    background-color: var(--naranja-btn);
    color: #ffffff;
}

.black-btn {
    background-color: var(--secondary-btn-bg);
    color: var(--secondary-btn-text);
}

.white-btn {
    background-color: var(--secondary-btn-text);
    color: var(--secondary-text);
    border: 1px solid rgba(39, 39, 39, 0.1) !important;
}

.select-red {
    background: rgba(227, 30, 40, 0.1) !important;
}

.select-red span,
.select-red i {
    color: #e31e28 !important;
}

.select-orange {
    background: rgba(246, 146, 33, 0.15) !important;
    color: #f18f1f !important;
}

.select-orange span,
.select-orange i {
    color: #f18f1f !important;
}

.inline {
    display: inline;
}

.full-height {
    height: 100% !important;
}

.full-width {
    width: 100% !important;
}

.half-width {
    width: 50% !important;
}

.one-third-width {
    width: 33% !important;
}

.two-third-width {
    width: 66% !important;
}

.one-fourth-width {
    width: 25% !important;
}

.one-fifth-width {
    width: 20% !important;
}

.screen-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px !important;
}

.max-screen {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.max-screen__bottom,
.remaining-width {
    flex: 1 1 auto;
}

.max-screen__bottom__2 {
    flex: 1 1 auto;
    height: 1px;
}

.cursor {
    cursor: pointer;
}

.flexer {
    display: flex;
}

.flexer-wrapper {
    flex-wrap: wrap;
}

.flexer-vcenter {
    align-items: center;
}

.flexer-spaced {
    justify-content: space-between;
}

.flexer-1 {
    flex: 0 0 8%;
}

.flexer-2 {
    flex: 0 0 20%;
}

.flexer-3 {
    flex: 0 0 25%;
}

.flexer-4 {
    flex: 0 0 32.3%;
}

.flexer-5 {
    flex: 0 0 40.6%;
}

.flexer-6 {
    flex: 0 0 49%;
}

.flexer-7 {
    flex: 0 0 57.3%;
}

.flexer-8 {
    flex: 0 0 65.6%;
}

.flexer-9 {
    flex: 0 0 75%;
}

.flexer-10 {
    flex: 0 0 79%;
}

.flexer-11 {
    flex: 0 0 90%;
}

.flexer-12 {
    flex: 0 0 98%;
}

.line-margin-0 {
    margin-bottom: 0em !important;
    margin-top: 0em !important;
}

.line-margin-xs {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

.line-margin-s {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

.side-margin-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.rating-text-setter {
    font-size: 24px;
    line-height: 29px;
    border-left: 1px solid lightgray;
    padding-left: 15px;
}

.breakable {
    word-break: break-word;
}

.fixed {
    position: fixed;
}

@media screen and (max-width: 1024px) {
    .flexer {
        flex-wrap: wrap;
    }

    .flexer-mobile {
        display: initial;
    }

    .center-mobile {
        margin: 0px auto !important;
        text-align: center !important;
        justify-content: center !important;
    }

    .full-width-mobile {
        width: 100% !important;
        padding: 0px !important;
    }

    .mobile-hidden {
        display: none !important;
    }

    .mobile-shown {
        display: block !important;
    }

    .Modal__container {
        width: 50% !important;
    }

    .Modal__full-screen-container {
        width: 100% !important;
    }

    .star-container {
        padding: 0px 2px !important;
    }

    .widget-svg {
        width: 14px !important;
        height: 14px !important;
    }

    .rating-text-setter {
        font-size: 14px;
        line-height: 14px;
    }

    .dashboard-wrapper__card:first-child {
        margin: 15px !important;
    }

    .dashboard-wrapper__card {
        margin: 15px !important;
    }

    .dashboard-wrapper__card:last-child {
        margin: 15px !important;
    }
}

.hidden {
    display: none;
}

.shown {
    display: block;
}

.borderless {
    border: none !important;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.on-top {
    z-index: 1;
}

.no-border {
    border: none;
}

// Elements

.input-wrapper {
    position: relative;
}

.input-wrapper__icon-right {
    position: absolute;
    top: 0;
    right: 0;
    line-height: inherit;
}

.input-wrapper__icon-left {
    position: absolute;
    top: 0;
    left: 0;
}

.input-field {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-top: 35px;
}

.input-field__label {
    font-size: 13px;
    color: var(--secondary-text);
}

.input-field__input {
    border: none;
    border-bottom: 1px solid var(--border);
    margin-top: 5px;
    width: 100%;
    font-size: 15px;
    color: var(--main-text);
    background: var(--card-bg);
    padding: 5px;
}

.input-field__filters {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-top: 15px;
}

.checkbox {
    margin-right: 25px;
}

.radio-btn__label {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: lighter;
    font-size: 15px;
    line-height: 18px;
    color: var(--main-text);
    margin: 0.5rem 2%;
}

.radio-btn__input {
    margin-right: 10px;
}

.icon {
    color: var(--secondary-text);
    font-size: 20px;
}

.link {
    color: var(--link);
    text-decoration: none;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: lighter;
    font-size: 15px;
    line-height: 21px;
}

.button {
    border-radius: 5px;
    padding: 12px;
    border: none;
    font-family: var(--main-font);
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
    height: fit-content;
}

.search-input {
    background: var(--main-bg);
    border-radius: 8px;
    border: none;
    padding: 10px 40px;
    width: 100%;
    box-sizing: border-box;
}

.search-input-2 {
    background: var(--card-bg);
    border: none;
    width: 100%;
    box-sizing: border-box;
}

.search-input-3 {
    background: var(--card-bg);
    border-radius: 4px;
    border: 1px solid rgb(204, 204, 204);
    padding: 10px 40px;
    width: 100%;
    box-sizing: border-box;
}

.search-results {
    background: var(--main-bg);
    padding: 10px 40px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 1px 1px 0px 0px var(--border);
}

.search-results:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.search-results:hover {
    background-color: var(--card-bg);
}

.search-icon {
    padding: 10px;
    font-size: 15px;
}

.table {
    width: 100%;
}

.table-header th,
.table-rows td {
    padding: 15px 0px;
    border-bottom: 1px solid #ebebeb;
}

.file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

// User Registration
.regist-card {
    background: var(--card-bg);
    border-radius: 5px;
    padding: 50px;
    margin: 50px auto;
}

// Header

.profile-menu__checkbox {
    display: none;
}

.profile-menu__name {
    padding: 0px 10px;
}

.profile-menu__image {
    height: 2.5rem;
    vertical-align: middle;
    border-radius: 50%;
    background-color: var(--naranja-btn);
    width: 40px;
    height: 40px;
}

.profile-menu__list {
    list-style: none;
    background-color: var(--card-bg);
    border-radius: 3px;
    position: absolute;
    right: 0;
    margin-right: 2%;
    margin-left: 2%;
    margin-top: 10px;
    width: 150px;
    padding: 0px;
    z-index: 9;
    box-shadow: -2px -2px 10px 0px var(--shadow);
}

.profile-menu__list::before {
    content: " ";
    position: absolute;
    left: auto;
    right: 7px;
    top: -7px;
    border: 10px solid;
    border-color: var(--card-bg) transparent transparent var(--card-bg);
    box-shadow: -2px -2px 2px -1px var(--shadow);
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.profile-menu__item {
    padding: 5px 10px;
    color: var(--main-text);
}

.profile-menu__item:hover {
    background-color: var(--main-bg);
    color: var(--link);
    border-radius: 3px;
}

.header-tabs {
    border-top: 1px solid var(--main-bg);
    box-shadow: 0px 6px 16px -15px rgba(0, 0, 0, 0.295427);
}

.text-icon {
    font-size: 15px;
    line-height: 18px;
    color: var(--secondary-text);
}

.menu-tab {
    margin: 0px 15px;
    padding: 0px 10px;
    border-bottom: 2px solid var(--card-bg);
}

.wcc-org {
    margin: 0px 15px;
    padding: 0px 10px;
    font-size: large;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    text-transform: uppercase;
  }

.menu-tab__project {
    border-bottom: 2px solid var(--main-bg);
}

.menu-tab:hover,
.menu-tab__active {
    border-bottom: 2px solid var(--naranja-btn);
    transition: $trasition;
}

.menu-tab:hover p,
.menu-tab:hover i,
.menu-tab__active p,
.menu-tab__active i {
    color: var(--link);
    transition: $trasition;
}

.menu-tab i {
    margin-right: 10px;
}

.project-btn {
    background-color: var(--naranja-btn);
    display: inline-block;
    padding: 0px 30px;
    border: 2px solid var(--naranja-btn);
}

.project-btn i {
    margin-right: 10px;
    color: white;
}

.project-btn:hover {
    background-color: white;
    border: 2px solid var(--naranja-btn);
}

.project-btn:hover p,
.project-btn:hover i {
    color: var(--link);
}

// Modal

.Modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.Modal__container {
    background-color: var(--card-bg);
    padding: 40px;
    width: 35%;
    border-radius: 5px;
    overflow: auto;
    max-height: 80%;
}

.Modal__full-screen-container {
    width: 100%;
    height: 92%;
    max-height: 92%;
    padding-left: 15%;
    padding-right: 15%;
    border-radius: 15px 15px 0 0;
    position: fixed;
    bottom: 0%;
}

.Modal__close {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 20px;
}

// Content

.content-wrapper {
    background: var(--card-bg);
    margin: 30px auto;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
}

.content-wrapper__analytics {
    background: var(--card-bg);
    margin: 10px auto;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
}

//// Profile

.simple-profile {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    height: 100%;
}

.simple-profile__sections {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.simple-profile__sections:last-child {
    border-bottom: none;
}

.simple_profile__upper-sections {
    margin: 20px 0px 0px 0px;
}

.icon-text {
    display: inline-flex;
    word-wrap: anywhere;
}

.simple-profile__picture {
    width: 50%;
    border-radius: 5px;
    background: var(--secondary-text);
}

.edit-profile__picture {
    width: 10%;
    border-radius: 5px;
    background: var(--secondary-text);
}

.social-icons {
    font-size: 25px;
    color: var(--naranja-btn);
    margin: 5px;
    width: 36px;
}

.group-icon {
    font-size: 25px;
    background: var(--naranja-btn);
    color: white;
    border-radius: 50%;
    margin: 0px 5px 0px 5px;
    text-align: center;
    padding: 10px;
}

.advanced-profile {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.exp-rev-section {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 20px;
}

.bubble {
    display: inline-block;
    position: relative;
    box-shadow: 2px 1px 4px 1px var(--shadow);
    padding: 0px 15px;
    border-radius: 3px;
    width: -webkit-fill-available;
}

.bubble:before {
    content: " ";
    position: absolute;
    left: 20px;
    right: auto;
    bottom: -7px;
    border: 7px solid;
    border-color: transparent var(--card-bg) var(--card-bg) transparent;
    box-shadow: 2px 2px 2px 0px var(--shadow);
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.reviewer-wrapper {
    margin: 20px 0px;
}

.reviewer-wrapper__info {
    margin: 0px 15px;
}

.rating-box {
    background-color: var(--main-bg);
    border-radius: 40px;
    padding: 5px 0px;
}

.warning-message {
    background-color: var(--warning);
    padding: 15px;
}

// Organization Profile
.simple-org__picture {
    width: 50%;
    border-radius: 5px;
    background: var(--card-bg);
}

.edit-org-profile__picture {
    width: 10%;
    border-radius: 5px;
    background: var(--card-bg);
}

// User Resources

.dashboard-section {
    padding: 20px 30px;
}

// User Organizations view
.dashboard-user-section {
    padding: 20px 0px;
    min-height: 400px;
}

.org-icon__picture {
    width: 30%;
    border-radius: 5px;
    background: var(--card-bg);
    margin-left: 8px;
}

// Toggle

.toggle-wrapper {
    margin: 10px 0px;
}

.toggle {
    margin: 2px 0px;
}

.toggle-switch {
    position: relative;
    width: 65px;
    margin: 0px auto;
    &-checkbox {
        display: none;
    }
    &-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 0 solid #bbb;
        border-radius: 20px;
        margin: 0;
    }
    &-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
        font-family: var(--main-font);
        &:before,
        &:after {
            display: block;
            float: left;
            width: 50%;
            height: 25px;
            padding: 0;
            line-height: 25px;
            font-size: 14px;
            color: white;
            font-weight: bold;
            box-sizing: border-box;
        }
        &:before {
            content: "On";
            text-transform: uppercase;
            padding-left: 10px;
            background-color: var(--naranja-btn);
            color: #fff;
        }
    }
    &-disabled {
        background-color: #ddd;
        cursor: not-allowed;
        &:before {
            background-color: #ddd;
            cursor: not-allowed;
        }
    }
    &-inner:after {
        content: "Off";
        text-transform: uppercase;
        padding-right: 10px;
        background-color: #bbb;
        color: #fff;
        text-align: right;
    }
    &-switch {
        display: block;
        width: 17px;
        height: 17px;
        margin: 4px;
        background: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        border: 0 solid #bbb;
        border-radius: 20px;
        transition: all 0.3s ease-in 0s;
    }
    &-checkbox:checked + &-label {
        .toggle-switch-inner {
            margin-left: 0;
        }
        .toggle-switch-switch {
            right: 0px;
        }
    }
}

// Dashboard

.dashboard-wrapper {
    margin: 30px auto;
}

.dashboard-wrapper__card {
    background-color: var(--card-bg);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    margin: 10px auto;
}

.dashboard-wrapper__card:first-child {
    margin: 10px auto 10px;
}

.dashboard-wrapper__card:last-child {
    margin: 10px auto 10px;
}

.dashboard-wrapper__content {
    padding: 20px 30px;
    box-sizing: border-box;
}

.custom-icon {
    border-radius: 5px;
    padding: 2px 10px;
    margin-right: 10px;
}

.background-icon {
    background-color: var(--main-bg) !important;
}

.custom-icon i {
    color: var(--secondary-text);
}

.custom-icon:hover {
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
}

.custom-icon:hover i {
    color: var(--link);
}

.columns-selector {
    position: absolute;
    width: 370px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    right: 0;
    top: 35px;
    background-color: var(--card-bg);
    z-index: 1;
}

.selected-filters {
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.05);
    padding: 5px;
    margin: 10px 5px;
}

.reset-selected-filters {
    border-radius: 5px;
    // border: solid 1px rgba(0, 0, 0, 0.05);
    padding: 3px 5px;
    margin: 10px 5px;
}

// project generation

.step {
    border: 1px solid var(--step-menu);
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    color: var(--step-menu);
    font-size: 24px;
    padding: 5px;
}

.step-active {
    background: var(--link);
    border: 1px solid var(--link);
    color: var(--link);
}

.step-past {
    background: var(--link);
    border: 1px solid var(--link);
    color: var(--card-bg);
}

.line {
    border-bottom: 2px dotted var(--step-menu);
    width: 83%;
}

.form-icon {
    margin-right: 30px;
    color: var(--secondary-text);
    font-size: 20px;
    margin-top: 10px;
}

div[class^="ReactFlagsSelect"] button {
    border: none;
    padding: 5px 0px;
}

div[class^="ReactFlagsSelect"] button[aria-expanded="true"]:after {
    border-bottom: 5px solid var(--main-text);
}

div[class^="ReactFlagsSelect"] button[aria-expanded="false"]:after {
    border-top: 5px solid var(--main-text);
}

div[class^="ReactFlagsSelect"] button span {
    color: var(--main-text);
}

.chip,
.chip i {
    background: var(--link) !important;
    color: white !important;
    margin-bottom: 0px;
}

// project page

.project-sides {
    background: var(--card-bg);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    margin-top: 1px;
}

.project-links {
    padding: 10px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.select-special {
    border-radius: 5px;
    margin-top: 0px;
    border-bottom: none;
}

.upload-wrapper {
    border-radius: 3px;
    border: 1px dashed lightgray;
    padding: 10px;
    width: fit-content;
}

.upload-wrapper__icon {
    background: orange;
    padding: 6px;
    color: white;
    border-radius: 5px;
}

.comment-bubble {
    background: var(--card-bg);
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.15);
    border-radius: 60px;
}

.comment-bubble input {
    border: none;
    padding: 20px;
    border-radius: 60px;
}

.small-size-image {
    width: 50%;
    border-radius: 5px;
    background: var(--card-bg);
}

.full-size-image {
    // height: 2.5rem;
    vertical-align: middle;
    background-color: var(--card-bg);
    width: 400px;
    height: 400px;
}

// Chats

.chat-wrapper {
    background-color: var(--card-bg);
    border-radius: 8px;
    margin: 30px auto !important;
}

.chats-input {
    margin-top: 0px !important;
}

.chats-input input,
.chats-input textarea {
    border-bottom: none !important;
    padding: 0px !important;
    margin: 0px !important;
    height: 100%;
}

.chats-multi .search-wrapper {
    border: none !important;
    padding: 0px !important;
}

.chats-multi .chip {
    margin-bottom: 0px !important;
}

.filters-multi .optionContainer {
    font-size: 15px;
    background-color: var(--card-bg);
}

.filters-multi .checkbox {
    filter: grayscale(1);
}

.filters-multi .optionContainer li {
    color: var(--secondary-text);
}

.filters-multi .optionContainer li:hover {
    color: var(--multi-options);
    background-color: var(--link);
}

.optionContainer {
    font-size: 15px;
    background-color: var(--card-bg);
}

.optionContainer span {
    color: var(--secondary-text);
}

.chats-max-screen {
    display: flex;
    flex-direction: column;
}

.chats__date-bubble {
    border-radius: 13px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    padding: 5px 20px;
    width: fit-content;
}

.chats__broadcast {
    background: rgba(246, 146, 33, 0.1);
    padding: 20px;
}

.chats__inactive {
    border-left: 4px solid var(--card-bg);
}

.chats__active,
.chats__item:hover {
    background: var(--main-bg);
    border-left: 4px solid var(--naranja-btn);
}

.chats__unread {
    background: var(--naranja-btn);
    padding: 0px 8px;
    border-radius: 8px;
    color: var(--secondary-btn-text);
    font-size: 10px;
    font-weight: bolder;
}

.start-thread {
    display: none;
}

.thread-wrapper:hover .start-thread {
    display: block !important;
}

.parent-thread {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
}

// tootips

/* Wrapping */
.tooltip-wrapper {
    display: inline-block;
    position: relative;
}

/* Absolute positioning */
.tooltip-tip {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: var(--secondary-text);
    background: var(--main-bg);
    font-size: 14px;
    font-family: var(--main-font);
    box-shadow: 0px 4px 10px var(--shadow);
    line-height: 1;
    z-index: 100;
    white-space: nowrap;
}

.tooltip-tip.noBg {
    background: var(--card-bg);
}

/* Absolute positioning */
.tooltip-tip.top {
    top: calc(20px * -1);
}

/* Absolute positioning */
.tooltip-tip.right {
    left: calc(100% + 20px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
}

/* Absolute positioning */
.tooltip-tip.bottom {
    bottom: calc(20px * -1);
}

/* Absolute positioning */
.tooltip-tip.left {
    left: auto;
    right: calc(100% + 20px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
}

// rating card
.rating-wrapper {
    background: var(--card-bg);
    margin: 20px auto;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 2% 2%;
}

.rating-bar-5 {
    background-color: #76caa0;
    height: 21px;
}

.rating-bar-4 {
    background-color: #add884;
    height: 21px;
}

.rating-bar-3 {
    background-color: #ffd93b;
    height: 21px;
}

.rating-bar-2 {
    background-color: #ffb33a;
    height: 21px;
}

.rating-bar-1 {
    background-color: #ff8d57;
    height: 21px;
}

.wcc-hasconfidence {
    i {
        cursor: pointer;
    }
    .wcc-confidence {
        // opacity: 0;
        display: none;
    }
    &:hover .wcc-confidence {
        // opacity: 1 !important;
        display: block !important;
        position: relative;
    }
}

.confidence-card {
    position: absolute;
    width: 300px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background-color: #fff;
    z-index: 1;
}

.confidence-icon {
    font-size: 80px;
    line-height: 115px;
}

.confidence-5 {
    color: #76caa0;
}

.confidence-4 {
    color: #add884;
}

.confidence-3 {
    color: #ffd93b;
}

.confidence-2 {
    color: #ffb33a;
}

.confidence-1 {
    color: #ff8d57;
}

.confidence-0 {
    color: #dfdfdf;
}

.confidence-frame {
    border: solid 1px rgba(0, 0, 0, 0.05);
    margin: 20px;
    border-radius: 5px;
    text-align: center;
}

.mv-profpic .confidence {
    margin-right: -33px;
}

.dv-star-rating label {
    margin: 0 5px;
}

.dv-star-rating label i {
    color: #DFDFDF;
}

.wcc-steps {
    justify-content: center;
    & > div:last-child {
        flex: unset;
    }
}


#score-form .co-unknown {
    label:nth-child(10) i {
        color: #FF8D57;
    }
}
#score-form .co-low {
    label:nth-child(10) i {
        color: #FF8D57;
    }
    label:nth-child(8) i {
        color: #ffb33a;
    }
}

#score-form .co-medium {
    label:nth-child(10) i {
        color: #FF8D57;
    }
    label:nth-child(8) i {
        color: #ffb33a;
    }
    label:nth-child(6) i {
        color: #ffd93b;
    }
}
#score-form .co-high {
    label:nth-child(10) i {
        color: #FF8D57;
    }
    label:nth-child(8) i {
        color: #ffb33a;
    }
    label:nth-child(6) i {
        color: #ffd93b;
    }
    label:nth-child(4) i {
        color: #add884;
    }
}
#score-form .co-verified {
    label:nth-child(10) i {
        color: #FF8D57;
    }
    label:nth-child(8) i {
        color: #ffb33a;
    }
    label:nth-child(6) i {
        color: #ffd93b;
    }
    label:nth-child(4) i {
        color: #add884;
    }
    label:nth-child(2) i {
        color: #76caa0;
    }
}

.wcc-contimages {
    overflow-x: scroll;
    white-space: nowrap;

    .wcc-acimages {
        display: inline-block;
        width: 80px;
        overflow: visible;
        border-radius: 40px;
        margin-right: 45px;
        padding-bottom: 20px;

        &:last-child {
            margin-right: 30px;
        }

        .wcc-confidence {
            float: right;
            margin-right: -30px;
            margin-top: -43px;
            display: none;
        }
        &:hover .wcc-confidence{
            display: block;
            cursor: pointer;
        }

        .wcc-acimage {
            img {
                width: 100%;
                max-width: 80px;
                border-radius: 5px;
                cursor: pointer;
            }
        }
    }
}


// Pagination
.wcc-pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .Mui-selected{
        background-color: var(--naranja-btn) !important;
        color: #fff !important;
    }
}

.MuiButtonBase-root-MuiIconButton-root path {
    color: var(--main-text) !important;
}

.MuiButtonBase-root-MuiIconButton-root:hover path {
    color: var(--link) !important;
    background: var(--main-bg !important);
}

.MuiList-root-MuiMenu-list ul {
    background: var(--card-bg)!important;
    border: 1px solid var(--border) !important;
    border-radius: 0px !important;
}

.MenuItem:hover {
    color: var(--link);
}

