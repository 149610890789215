.wcc-loading {
  background: var(--naranja-btn) !important;
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;

  .wcc-circle {
    display: inline-block;
    transform: translateZ(1px);

    > div {
      display: inline-block;
      width: 128px;
      height: 128px;
      margin: 8px;
      border-radius: 50%;
      background: #fff;
      animation: wcc-circle 4.4s cubic-bezier(0, 0.4, 0.10, 1) infinite;
      text-align: center;
      line-height: 120px;
    }
  }
}

@keyframes wcc-circle {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
