.light {
    --main-text: #272727;
    --secondary-text: rgba(39, 39, 39, 0.7);
    --link: #F69221;
    --error: red;
    --main-bg: #F4F6F9;
    --card-bg: white;
    --naranja-btn: #F19126;
    --main-font: 'proxima-nova', sans-serif;
    --logo: url('../images/torch-logo.png');
    --border: rgba(0, 0, 0, 0.15);
    --shadow: rgba(0, 0, 0, 0.10);
    --secondary-btn-bg: #272727;
    --secondary-btn-text: white;
    --step-menu: rgba(77, 77, 77, 0.2);
    --warning: rgba(255, 152, 0, 0.2);
    --multi-options: white;
}

.dark {
    --main-text: white;
    --secondary-text: rgba(255, 255, 255, 0.7);
    --link: #F69221;
    --error: red;
    --main-bg: #272727;
    --card-bg: #313131;
    --naranja-btn: #F19126;
    --main-font: 'proxima-nova', sans-serif;
    --logo: url('../images/torch-logo-white.png');
    --border: rgba(255, 255, 255, 0.1);
    --shadow: rgba(255, 255, 255, 0.05);
    --secondary-btn-bg: white;
    --secondary-btn-text: #272727;
    --step-menu: rgba(77, 77, 77, 0.2);
    --warning: rgba(255, 152, 0, 0.2);
    --multi-options: white;
}
